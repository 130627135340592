.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 8px;
  p {
    font-size: 12px;
  }
}

.spinner {
  border: 8px solid #cfddfc;
  border-radius: 50%;
  border-top: 8px solid var(--colors-theme-500-base, #346df1);
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  z-index: 1000;
}

/* Keyframes for spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
