/** GENERAL **/

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");
/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
  background-color: #0d274d;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

input:focus {
  outline: none;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

button {
  cursor: pointer;
}

body {
  font-family: "Sora", "Readex Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  font-weight: 400;
  text-align: center;
  scrollbar-color: #0d274d #263f65;
  scrollbar-width: 8px;
}
div#root,
div.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  min-width: fit-content;
  max-width: 100%;
  overflow-x: hidden;
  background-color: #0d274d;
  color: white;
}
#cisco-logo {
  position: absolute;
  top: 36px;
  left: 46px;
}

.italic {
  font-style: italic;
}
.underline {
  text-decoration: underline;
}

.paragraph-tiny {
  font-size: 11px;
  font-weight: 400;
  line-height: 14px; /* 127.273% */
}

.paragraph-small {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18px !important; /* 138.462% */
}

.paragraph-default {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.text-button,
.icon-button {
  border: none;
  background: transparent;
  color: #fff;
  cursor: pointer;
}

.primary-button,
.secondary-button {
  height: 60px;
  border-radius: 15px;
  background: linear-gradient(180deg, #65c67f 0%, #30d557 100%);
  border: none;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
}
.primary-button {
  border-radius: 15px;
  background: linear-gradient(180deg, #65c67f 0%, #30d557 100%);
}
.secondary-button {
  background: transparent;
  border-radius: 15px;
  border: 1px solid #fff;
}

.dashboard-layout {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.left-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #0d274d;
  width: 546px;
  flex-shrink: 0;
  /* Button/Shadow Hover */
  box-shadow: 0px 8px 16px 0px rgba(23, 25, 28, 0.24);
  min-width: 400px;
  overflow: hidden auto;
  padding-top: 36px;
  @media screen and (max-width: 1440px) {
    width: 30%;
    > div {
      max-width: 90%;
    }
  }
  .left-panel-logo {
    margin: 0 auto 20px 46px;
  }
}

.panel-description {
  color: #c4dafb;
  text-align: center;
  font-family: Sora;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  max-width: 70%;
}

.error {
  color: #e74c3c !important;
}
.error-bg {
  background-color: #e74c3c !important;
}
.success {
  color: #23b26b !important;
}
.success-bg {
  background-color: #23b26b !important;
}

.maplibregl-map {
  height: 100% !important;
}

footer {
  text-align: center;
  padding: 20px 0;
  background-color: #fff;
  width: fit-content;
  img {
    width: 120px;
  }
}

/** HomePage **/

button#faq {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: var(--Primary-Buttons-Themed-Button-Background-idle, #0d44c2);
  color: white;
  border-radius: 25px;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  z-index: 2;
}

/* Overlay to dim background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.slider-container {
  width: 100%;
  margin: 0 auto;
}
.slider-container:not(.single) .slick-slide {
  transform: scale(0.8) translateZ(0);
  will-change: transform, opacity;
  transition: transform 0.3s ease, opacity 0.3s ease, width 0.3s ease;

  .card {
    height: 346px !important;
  }
}
.slider-container:not(.single) .slick-list {
  padding-top: 20px !important;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /**/
}

.slick-slide {
  width: 280px !important;
  height: 380px !important;
}
.slick-current {
  transform: scale(1) translateZ(0) !important;
  opacity: 1 !important;
  position: relative;
  /*margin-top: -17px;*/
  .card {
    height: 380px !important;
  }
}

/* Style related new carousel design on result page*/
.single.demo-mode .slick-current .card {
  height: 280px !important;
  box-shadow: none !important;
}

.single.demo-mode .card {
  box-shadow: none !important;
}

.slick-next:before,
.slick-prev:before {
  content: none !important;
}

.single.demo-mode .slick-slide {
  width: 400px !important;
  height: 280px !important;
}

.slick-prev,
.slick-next {
  z-index: 9;
}
.slick-prev {
  left: 0px !important;
}
.slick-next {
  right: 0px !important;
}

.single .slick-prev {
  /*transform: translate(-24px, calc(-50% - 24px));*/
  transform: translateY(calc(-50% - 24px));
  left: -30px !important;
}
.single .slick-next {
  /*transform: translate(24px, calc(-50% - 24px));*/
  transform: translateY(calc(-50% - 24px));
  right: -30px !important;
}

.single.demo-mode .card h3 {
  color: #fff;
  font-size: 56px;
  font-weight: 700;
  line-height: 100%; /* 56px */
  margin-bottom: 24px;
}

.single.demo-mode .card p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  /*margin-bottom: 40px;*/
  text-align: left;
}

.single.demo-mode .card {
  padding: 0px;
  height: 280px !important;
}

.slick-dots {
  bottom: -64px !important;
}
@media (max-height: 800px) {
  .slick-dots {
    bottom: -52px !important;
  }
}

.slick-dots li {
  width: 12px !important;
  height: 12px !important;
  margin: 0 4px !important;
  opacity: 0.5;
}

.slick-dots li.slick-active {
  opacity: 1;
}
/* End of Style related new carousel design on result page*/

.slick-center {
  z-index: 2;
}

/*Map marker & popup*/

.maplibregl-popup-content {
  padding: 0 !important;
  border-radius: 8px !important;
  font-family: Sora;
  font-style: normal;
}

.maplibregl-popup-content,
.maplibregl-popup-close-button {
  color: #fff;
}

.maplibregl-popup-close-button {
  top: 6px;
  right: 6px;
}

.indoor-navigation-popup {
  max-width: unset !important;
}
.indoor-navigation-popup .maplibregl-popup-tip {
  display: none !important;
}
.indoor-navigation-popup .maplibregl-popup-content {
  /*background-color: #1d2023 !important;*/
  background-color: transparent !important;
  box-shadow: none !important;
}
.indoor-navigation-popup .maplibregl-popup-anchor-left {
  margin-top: -40px;
}

.indoor-navigation-popup.maplibregl-popup-anchor-left .maplibregl-popup-tip {
  display: none !important;
}

.asset-tracking-popup .maplibregl-popup-content {
  background-color: #2e343b !important;
}

.asset-tracking-popup.maplibregl-popup-anchor-left .maplibregl-popup-tip {
  border-right-color: #2e343b !important;
}
.asset-tracking-popup {
  margin-left: 8px;
}

.heatmap-popup .maplibregl-popup-tip {
  display: none !important;
}

@media (max-width: 480px) {
  div#root,
  div.App {
    display: block;
    min-width: unset;
  }
  #cisco-logo {
    display: none;
  }
  .dashboard-layout {
    flex-direction: column-reverse;
    height: auto;
  }
  .left-panel {
    width: 100%;
    overflow: hidden auto;
    min-width: 100%;
  }
  .panel-header {
    h5 {
      font-size: 20px;
      line-height: 23.292px; /* 116.462% */
      letter-spacing: -0.388px;
      margin-bottom: 14px;
    }
    h2 {
      font-size: 40px;
      line-height: 59.201px; /* 148.003% */
      letter-spacing: -2.329px;
    }
    .completed {
      color: var(--Colors-emotional-success-600, #23b26b);
    }
  }
  .panel-description {
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: -0.194px;
  }
  button#faq {
    bottom: unset;
    top: 20px;
  }
  .maplibregl-canvas,
  .maplibregl-map {
    position: static !important;
  }

  .left-panel-logo {
    display: none;
  }
  .slider-container.single .card {
    min-height: 270px;
  }

  .single.demo-mode .slick-slide {
    width: 240px !important;
    height: 320px !important;
  }
  .slick-slide {
    width: 198px !important;
    height: 270px !important;
  }
  .slick-current {
    .card {
      height: 270px !important;
    }
  }
  .single.demo-mode .card h3 {
    font-size: 36px;
  }
  .single.demo-mode .card p {
    font-size: 12px;
    margin-bottom: 20px;
  }

  .slick-dots {
    bottom: -32px !important;
  }

  .indoor-navigation-marker img {
    width: 30px;
  }
  .indoor-navigation-popup {
    margin-top: -16px;
  }
  .indoor-navigation-popup .maplibregl-popup-content {
    margin-top: 0;
  }
  .maplibregl-popup-anchor-top.indoor-navigation-popup .maplibregl-popup-tip {
    border-bottom-color: #2e343b !important;
  }
}
